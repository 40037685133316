import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import styles from './gig-list-compact-styles.scss';
import { NO_IMAGE_ID } from '../../../consts/image';

const GigListCompactItem = ({
  title,
  date,
  img,
  slug,
  venueDataName,
  cb = () => null,
}) => (
  <li className={styles.item}>
    <Link
      onClick={cb}
      className={styles.itemLink}
      href={`/gig/${slug}`}
      to={`/gig/${slug}`}
    >
      <h4 className={styles.itemDate}>
        {' '}
        <time dateTime={date}>{moment(date).format('dddd Do MMMM')}</time>
      </h4>
      <img
        className={styles.itemImg}
        alt={img ? img.alt : 'stage'}
        src={
          img
            ? `https://res.cloudinary.com/stickyfloors/image/upload/c_lfill,e_blackwhite,g_face:auto,h_200,q_60,w_200/v1522329535/${
                img.cloudinaryId
              }.jpg`
            : `https://res.cloudinary.com/stickyfloors/image/upload/c_lfill,g_face:auto,h_200,q_60,w_200/v1522329535/${NO_IMAGE_ID}.jpg`
        }
      />
      <h3 className={styles.itemTitle}>{title}</h3>{' '}
      <h4 className={styles.itemPlace}> {venueDataName}</h4>
    </Link>
  </li>
);

GigListCompactItem.propTypes = {
  cb: PropTypes.func,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  venueDataName: PropTypes.string.isRequired,
  img: PropTypes.shape({
    alt: PropTypes.string,
    cloudinaryId: PropTypes.string,
  }),
};

class GigListCompact extends PureComponent {
  render() {
    return (
      <ul className={`${styles.conatiner} ${this.props.className}`}>
        {this.props.gigs.map((gig) => (
          <GigListCompactItem
            cb={this.props.cb}
            title={gig.title}
            key={gig._id}
            img={gig.img}
            slug={gig.slug}
            date={gig.date}
            venueDataName={gig.venueData.name}
          />
        ))}
      </ul>
    );
  }
}

GigListCompact.propTypes = {
  className: PropTypes.string,
  cb: PropTypes.func,
  gigs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      img: PropTypes.shape({
        alt: PropTypes.string,
        cloudinaryId: PropTypes.string,
      }),
      venueData: PropTypes.shape({ name: PropTypes.string.isRequired })
        .isRequired,
    })
  ),
};

export default GigListCompact;
