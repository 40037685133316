import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './back-button-styles.scss';

const BackButton = ({ className = '', onClick = () => {} }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick();
    navigate(-1);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={`${styles.backButton} ${className}`}
    >
     { '<'}
    </button>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default BackButton;
