import React from 'react'
import PropTypes from 'prop-types'

import styles from './social-media-link-styles.scss'

const SocialMediaLink = ({
  href,
  children,
  className = '',
  inverted = false,
  onClick = () => {},
}) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`${styles.socialMediaLink} ${inverted ? styles.inverted : ''} ${className}`}
    onClick={onClick}
  >
    {children}
  </a>
)

SocialMediaLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
}

export default SocialMediaLink
