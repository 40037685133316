import fetch from 'isomorphic-fetch';

const baseURL =
  typeof window === 'undefined'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8000}`
    : '';
export const REQUEST_GIG = 'REQUEST_GIG';
export const RECIEVE_GIG = 'RECIEVE_GIG';
export const RESET_GIGS = 'RESET_GIGS';

export const REQUEST_SIMILAR_GIGS = 'REQUEST_SIMILAR_GIGS';
export const RECIEVE_SIMILAR_GIGS = 'RECIEVE_SIMILAR_GIGS';

// TODO error handling

export function requestGig() {
  return { type: REQUEST_GIG };
}

export function receiveGig(gigDetails) {
  return { type: RECIEVE_GIG, gigDetails };
}

export function resetGig() {
  return { type: RESET_GIGS };
}

export function getGig({ slug }) {
  return (dispatch) => {
    dispatch(requestGig());

    return fetch(`${baseURL}/api/gig/${slug}`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveGig(res.gig));
      });
  };
}

export function requestSimilarGigs() {
  return { type: REQUEST_SIMILAR_GIGS };
}

export function receiveSimnilarGigs(similarGigs) {
  return { type: RECIEVE_SIMILAR_GIGS, similarGigs };
}

export function getSimilarGigs({ slug }) {
  return (dispatch) => {
    dispatch(requestSimilarGigs());
    return fetch(`${baseURL}/api/gig/${slug}/similar`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveSimnilarGigs(res.gigs));
      });
  };
}
