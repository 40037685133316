import React, { useState, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import styles from './hero-image-styles.scss';

const HeroImage = ({ src, alt = '', className = '', imageClassName = '' }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const onDomLoaded = () => {
      loadImg();
    };

    setIsLoading(true);
    setIsLoaded(false);
    setIsError(false);

    if (document.readyState === 'complete') {
      onDomLoaded();
    } else {
      window.addEventListener('load', onDomLoaded);
    }

    return () => {
      window.removeEventListener('load', onDomLoaded);
      cleanUp();
    };
  }, [src]);

  const onLoad = () => {
    setIsLoading(false);
    setIsLoaded(true);
    setIsError(false);
  };

  const onError = () => {
    setIsLoading(false);
    setIsLoaded(false);
    setIsError(true);
  };

  const getImage = () => (
    <img
      className={`${imageClassName} ${styles.imageAnimation}`}
      alt={alt}
      width="100%"
      src={src}
    />
  );

  const getContent = () => {
    if (isLoaded) {
      return getImage();
    }
    if (isLoading) {
      return <div className={styles.loader} />;
    }
    if (isError) {
      return null;
    }
    return null;
  };

  const cleanUp = () => {
    if (imageRef.current) {
      unloadImg();
    }
  };

  const loadImg = () => {
    imageRef.current = new Image();
    imageRef.current.src = src;
    imageRef.current.addEventListener('load', onLoad);
    imageRef.current.addEventListener('error', onError);
    imageRef.current.onerror = onError;
  };

  const unloadImg = () => {
    imageRef.current.removeEventListener('load', onLoad);
    imageRef.current.removeEventListener('error', onError);
    delete imageRef.current.onerror;
    delete imageRef.current.onload;
    try {
      delete imageRef.current.src;
    } catch (e) {
      // On Safari in Strict mode this will throw an exception,
      //  - https://github.com/mbrevda/react-image/issues/187
      // We don't need to do anything about it.
    }
    imageRef.current = null;
  };

  const content = getContent();
  const img = getImage();

  return (
    <div className={className || styles.wrapper}>
      {content}
      <noscript
        dangerouslySetInnerHTML={{
          __html: ReactDOMServer.renderToStaticMarkup(img),
        }}
      />
    </div>
  );
};

HeroImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
};

export default HeroImage;
