import { REQUEST_ARCHIVE, RECIEVE_ARCHIVE } from './action-creators';

const initialState = {
  isLoading: true,
};

const archiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ARCHIVE:
      return Object.assign({}, initialState);
    case RECIEVE_ARCHIVE:
      return Object.assign({}, { ...action.archiveData, isLoading: false });

    default:
      return state;
  }
};

export default archiveReducer;
