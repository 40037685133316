import React from 'react';

import GigsView from './views/gigs-view/gigs-view';
import BandsView from './views/bands-view/bands-view';
import AboutView from './views/about-view/about-view';
import VenuesView from './views/venues-view/venues-view';
import VenueDetailsView from './views/venue-details-view/venue-details-view';
import GigDetailsView from './views/gig-details-view/gig-details-view';
import BandDetailsView from './views/band-details-view/band-details-view';
import ArchiveView from './views/archive-view/archive-view';

const routesConfig = [
  { path: "/", element: <GigsView /> },
  { path: "/gigs", element: <GigsView /> },
  { path: "/bands", element: <BandsView /> },
  { path: "/about", element: <AboutView /> },
  { path: "/venues", element: <VenuesView /> },
  { path: "/venue/:slug", element: <VenueDetailsView /> },
  { path: "/gig/:slug", element: <GigDetailsView /> },
  { path: "/band/:slug", element: <BandDetailsView /> },
  { path: "/gigs/archive/:page", element: <ArchiveView /> },
  { path: "/gigs/archive", element: <ArchiveView /> },
];

export default routesConfig;
