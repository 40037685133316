import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { getVenues } from './action-creators';
import VenueList from '../../components/venues-list/venues-list';
import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text';
import styles from '../views-styles.scss';

const VenuesView = () => {
  const dispatch = useDispatch();
  const venues = useSelector(state => state.venuesData);
  const venuesText = useSelector(state => state.randomText.venues[0]);
  const isFirstPage = useSelector(state => state.router.routes.length === 0);

  useEffect(() => {
    // Only fetch on client-side if data is not already present
    if (!venues || venues.length === 0) {
      dispatch(getVenues());
    }
  }, [dispatch, venues]);

  return (
    <div className={styles.tranistionIn}>
      <Helmet title="List of venues">
        <link rel="canonical" href="https://stickyfloors.net/venues" />
      </Helmet>
      <h1>
        <StickyFloorsText
          className={styles.randomLine}
          textData={venuesText}
        />
      </h1>
      <VenueList />
    </div>
  );
};

// Static method for SSR data fetching
VenuesView.getInitialProps = () => {
  return [getVenues()];
};

VenuesView.propTypes = {
  venues: PropTypes.array,
  venuesText: PropTypes.array,
  isFirstPage: PropTypes.bool,
};

export default VenuesView;
