import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { getBands } from './action-creators';
import PlayingBandsList from '../../components/playing-bands-list/playing-bands-list';
import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text';
import viewStyles from '../views-styles.scss';

const BandsView = () => {
  const dispatch = useDispatch();
  const bandsText = useSelector((state) => state.randomText.bands[0]);
  const bands = useSelector((state) => state.bandsData);
  const [bandsLoadingDispatched, setBandsLoadingDispatched] = useState(false);
  useEffect(() => {
    // Only fetch on client-side if data is not already present
    if (!bands || (bands.length === 0 && !bandsLoadingDispatched)) {
      setBandsLoadingDispatched(true);
      dispatch(getBands());
    }
  }, [dispatch, bands, bandsLoadingDispatched]);

  return (
    <div>
      <Helmet title="List of bands">
        <link rel="canonical" href="https://stickyfloors.net/bands" />
      </Helmet>
      <h1>
        <StickyFloorsText
          className={viewStyles.randomLine}
          textData={bandsText}
        />
      </h1>
      <h2>Playing this month</h2>
      <PlayingBandsList />
    </div>
  );
};

// Static method for SSR data fetching
BandsView.getInitialProps = () => {
  return [getBands()];
};

BandsView.propTypes = {
  bandsText: PropTypes.array,
  isFirstPage: PropTypes.bool,
};

export default BandsView;
