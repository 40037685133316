import fetch from 'isomorphic-fetch';

const baseURL =
  typeof window === 'undefined'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8000}`
    : '';
export const REQUEST_VENUE = 'REQUEST_VENUE';
export const RECIEVE_VENUE = 'RECIEVE_VENUE';
export const RESET_VENUE_DATA = 'RESET_VENUE_DATA';

export function requestVenue() {
  return { type: REQUEST_VENUE };
}

export function receiveVenue(venueDetails) {
  return { type: RECIEVE_VENUE, venueDetails };
}

export function resetVenueData() {
  return { type: RESET_VENUE_DATA };
}

export function getVenue({ slug }) {
  return (dispatch) => {
    dispatch(requestVenue());
    return fetch(`${baseURL}/api/venue/${slug}`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveVenue(res.venue));
      });
  };
}
