import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getGigs } from './action-creators';
import GigsListWithDate from '../../components/gigs-list-with-date/gigs-list-with-date';
import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text';
import viewStyles from '../views-styles.scss';

const GigsView = () => {
  const dispatch = useDispatch();
  const gigs = useSelector((state) => state.gigsData);
  const gigsTextData = useSelector((state) => state.randomText.gigsListings[0]);
  const listingsTextData = useSelector(
    (state) => state.randomText.gigsListings[1]
  );
  const isFirstPage = useSelector((state) => state.router.routes.length === 0);

  useEffect(() => {
    // Only fetch on client-side if data is not already present
    if (!gigs || gigs.length === 0) {
      dispatch(getGigs());
    }
  }, [dispatch, gigs]);
  return (
    <div>
      <h1>
        <StickyFloorsText
          className={viewStyles.randomLine}
          textData={gigsTextData}
        />
        <StickyFloorsText
          className={viewStyles.randomLine}
          textData={listingsTextData}
        />
      </h1>
      {gigs.map((gigsByDay) => (
        <GigsListWithDate gigsList={gigsByDay} key={gigsByDay.date} />
      ))}
    </div>
  );
};

// Static method for SSR data fetching
GigsView.getInitialProps = () => {
  return [getGigs()];
};

GigsView.propTypes = {
  gigs: PropTypes.array,
  gigsTextData: PropTypes.array,
  listingsTextData: PropTypes.array,
  isFirstPage: PropTypes.bool,
};

export default GigsView;
