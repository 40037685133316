import React from 'react'
import TwitterIcon from '../icons/twitter-icon'
import SocialMediaLink from './social-media-link/social-media-link'

const TwitterButton = ({ url, className, inverted }) => (
  <SocialMediaLink inverted={inverted} className={className} href={url}>
    <TwitterIcon />
  </SocialMediaLink>
)
export default TwitterButton
