import React from 'react'
import SocialMediaLink from './social-media-link/social-media-link'
import SoundcloudIcon from '../icons/soundcloud-icon'

const SoundcloudButton = ({ url, className, inverted }) => (
  <SocialMediaLink inverted={inverted} className={className} href={url}>
    <SoundcloudIcon />
  </SocialMediaLink>
)

export default SoundcloudButton
