import React from 'react';

import { Link } from 'react-router-dom';
import Logo from '../logo/logo';
import MenuButton from '../menu/menu-button';
import styles from './header-styles.scss';

const Header = () => (
  <header className={styles.header}>
    <Link className={styles.logoLink} href="/" to="/">
      <Logo />
    </Link>
    <MenuButton />
  </header>
);

export default Header;
