// Pagination.js
import React from 'react';
import pagiinationStyles from './pagination-styles.scss';
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={`${pagiinationStyles.number}`}
          >
            {i}
          </button>
        );
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (currentPage <= 3) {
        endPage = 5;
      } else if (currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={`${pagiinationStyles.number} ${
              currentPage === i ? pagiinationStyles.active : ''
            }`}
          >
            {i}
          </button>
        );
      }

      if (startPage > 1) {
        pageNumbers.unshift(
          <span className={pagiinationStyles.dots} key="start-ellipsis">
            ...
          </span>
        );
        pageNumbers.unshift(
          <button
            className={pagiinationStyles.number}
            key={1}
            onClick={() => onPageChange(1)}
          >
            1
          </button>
        );
      }

      if (endPage < totalPages) {
        pageNumbers.push(
          <span className={pagiinationStyles.dots} key="end-ellipsis">
            ...
          </span>
        );
        pageNumbers.push(
          <button
            className={`${pagiinationStyles.number}`}
            key={totalPages}
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className={pagiinationStyles.pagination}>
      <button
        className={pagiinationStyles.next}
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        {'<<< Previous'}
      </button>
      {renderPageNumbers()}
      <button
        className={pagiinationStyles.prev}
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        {'Next >>>'}
      </button>
    </div>
  );
};

export default Pagination;
