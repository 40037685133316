import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import SideBar from './components/menu/side-bar';
import routesConfig from './routes-config';
import { useLocation } from 'react-router-dom';
import { routeUpdate } from './redux/router-reducer/action-creators';

import styles from './app-styles.scss';

// Loading fallback component
const LoadingFallback = () => <div className={styles.loading}>Loading...</div>;

const App = () => {
  // Use hooks instead of connect
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (window) {
      dispatch(routeUpdate(location.pathname));
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <HelmetProvider>
      <div className={styles.container}>
        <Helmet
          defaultTitle="StickyFloors | London's Random Gig Listings"
          titleTemplate="StickyFloors | %s"
        >
          {/* Primary Meta Tags */}
          <html lang="en" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            name="description"
            content="Discover cheap gigs and other shenanigans in London for under £15."
          />
          <meta
            name="keywords"
            content="london gigs, cheap concerts, underground music, live music london, alternative venues, punk shows, metal gigs, indie music"
          />
          <link
            rel="canonical"
            href={`https://stickyfloors.net${location.pathname}`}
          />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://stickyfloors.net${location.pathname}`}
          />
          <meta
            property="og:title"
            content="StickyFloors | London's Random Gig Listings"
          />
          <meta
            property="og:description"
            content="Discover cheap gigs and other shenanigans in London for under £15."
          />
          <meta
            property="og:image"
            content="https://stickyfloors.net/img/og-pic.jpg"
          />
          <meta property="og:site_name" content="Sticky Floors" />
          <meta property="og:locale" content="en_GB" />

          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@_stickyfloors_" />
          <meta name="twitter:creator" content="@_stickyfloors_" />
          <meta
            name="twitter:title"
            content="StickyFloors| London's Random Gig Listings"
          />
          <meta
            name="twitter:description"
            content="Discover cheap gigs and other shenanigans in London for under £15."
          />
          <meta
            name="twitter:image"
            content="https://stickyfloors.net/img/og-pic.jpg"
          />

          {/* Search Engine */}
          <meta name="robots" content="index, follow" />
          <meta name="googlebot" content="index, follow" />

          {/* Theme */}
          <meta name="theme-color" content="#000000" />

          {/* JSON-LD structured data */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "StickyFloors",
                "url": "https://stickyfloors.net",
                "description": "Discover cheap gigs and other shenanigans in London under £15."

              }
            `}
          </script>
        </Helmet>
        <Header />

        <div className={styles.container}>
          <Suspense fallback={<LoadingFallback />}>
            <Routes>
              {routesConfig.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </Suspense>
        </div>
        <SideBar />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

// Static method for SSR data fetching
App.getInitialProps = ({ url }) => {
  // You can add any app-level data fetching here
  return [];
};

export default App;
