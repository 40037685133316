import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styles from './side-bar-styles.scss';

import { closeMenu } from '../../redux/ui-reducer/action-creators';
import SocialMediaBar from '../social-media-bar/social-media-bar';

const SideBar = ({ isMenuOpen = false, onClick = ()=> null }) => {
  const clickHander = (buttonName, cb) => () => {
    // No analytics needed
    cb();
  };
  return (
    <div
      style={{ transform: `translateX(${isMenuOpen ? '0' : '100%'})` }}
      className={styles.sideBarContainer}
    >
      <nav className={styles.navigation}>
        <SocialMediaBar
          className={styles.socialMediaBar}
          twitterUrl="https://twitter.com/_stickyfloors_"
          facebookUrl="https://www.facebook.com/StickyFloors-1170717093093696"
          soundcloudUrl="https://soundcloud.com/stickyfloors"
        />
        <Link
          style={{ transform: 'rotate(10deg)' }}
          className={styles.menuLink}
          onClick={clickHander('Venues', onClick)}
          href="/venues"
          to="/venues"
        >
          venues
        </Link>
        <Link
          style={{ transform: 'rotate(-7deg)' }}
          className={styles.menuLink}
          onClick={clickHander('Gigs', onClick)}
          href="/gigs"
          to="/gigs"
        >
          gigs
        </Link>
        <Link
          style={{ transform: 'rotate(4deg)' }}
          className={styles.menuLink}
          onClick={clickHander('Bands', onClick)}
          href="/bands"
          to="/bands"
        >
          bands
        </Link>
        <Link
          style={{ transform: 'rotate(189deg)' }}
          className={styles.menuLink}
          onClick={clickHander('About', onClick)}
          href="/about"
          to="/about"
        >
          about
        </Link>
      </nav>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(closeMenu()),
});

SideBar.propTypes = {
  onClick: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};


const mapStateToProps = ({ uiState }) => ({
  isMenuOpen: uiState.isMenuOpen,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar);
