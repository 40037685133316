import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import htmlToText from 'html-to-text';

import { getGig, resetGig, getSimilarGigs } from './action-creators';
import FutureGigView from './future-gig-view/future-gig-view';

const GigDetailsView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const gigDetails = useSelector((state) => state.gigDetailsData.gigDetails);
  const isLoading = useSelector((state) => state.gigDetailsData.isLoading);
  const similarGigs = useSelector((state) => state.gigDetailsData.similarGigs);
  const isSimilarGigsLoading = useSelector(
    (state) => state.gigDetailsData.isSimilarGigsLoading
  );
  const isFirstPage = useSelector((state) => state.router.routes.length === 0);

  useEffect(() => {
    if (gigDetails.slug !== params.slug) {
      dispatch(resetGig());
      dispatch(getGig(params));
      dispatch(getSimilarGigs(params));
    }
  }, [dispatch, params.slug, gigDetails.slug]);

  const getTwitterImage = (venueImageSq, img) => {
    if (img && img.cloudinaryId) {
      return `https://res.cloudinary.com/stickyfloors/image/upload/c_fill,e_grayscale,g_faces,h_628,q_auto:best,w_1268/v1541513319/${img.cloudinaryId}.jpg`;
    }
    return `https://res.cloudinary.com/stickyfloors/image/upload/q_80/v1522329535/${venueImageSq}.jpg`;
  };

  const getStructuredData = (gigDetails) => {
    const performer =
      gigDetails.bands.length > 0
        ? gigDetails.bands.map((band) => ({
            '@type': 'PerformingGroup',
            name: band.name,
            sameAs: `https://stickyfloors.net/band/${band.slug}`,
          }))
        : undefined;

    return {
      '@context': 'http://schema.org',
      '@type': ['Event', 'MusicEvent'],
      name: gigDetails.title,
      description: htmlToText.fromString(gigDetails.description || '', {
        wordwrap: 160,
      }),
      startDate: moment(gigDetails.date).format(),
      endDate: moment(gigDetails.date).add(3, 'hours').format(),
      performer,
      location: {
        '@type': 'Place',
        name: gigDetails.venueData.name,
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'London',
          addressCountry: 'GB',
          streetAddress: gigDetails.venueData.address,
        },
        sameAs: `https://stickyfloors.net/venue/${gigDetails.venueData.slug}`,
      },
      image: getTwitterImage(gigDetails.venueData.imageSq, gigDetails.image),
      url: `https://stickyfloors.net/gig/${gigDetails.slug}`,
    };
  };

  if (isLoading || !gigDetails.title) {
    return null;
  }

  const description = htmlToText.fromString(gigDetails.description || '', {
    wordwrap: 160,
  });

  const bandsString = gigDetails.bands.map((band) => band.name).join(', ');

  const title = `${gigDetails.title} at ${gigDetails.venueData.name} - ${moment(
    gigDetails.date
  ).format('DD/MM/YYYY')}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content={`london gigs, ${bandsString}, ${gigDetails.venueData.name}, live music london`}
        />

        {/* OpenGraph / Facebook */}
        <meta property="og:type" content="music.event" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:url"
          content={`https://stickyfloors.net/gig/${gigDetails.slug}`}
        />
        <meta
          property="og:image"
          content={getTwitterImage(
            gigDetails.venueData.imageSq,
            gigDetails.image
          )}
        />
        <meta
          property="event:start_time"
          content={moment(gigDetails.date).format()}
        />
        <meta
          property="event:end_time"
          content={moment(gigDetails.date).add(3, 'hours').format()}
        />
        <meta
          property="place:location:latitude"
          content={gigDetails.venueData.location?.coordinates?.[1]}
        />
        <meta
          property="place:location:longitude"
          content={gigDetails.venueData.location?.coordinates?.[0]}
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={getTwitterImage(
            gigDetails.venueData.imageSq,
            gigDetails.image
          )}
        />
        <meta name="twitter:image:alt" content={`${title} - Event Poster`} />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify(getStructuredData(gigDetails))}
        </script>

        {/* Canonical URL */}
        <link
          rel="canonical"
          href={`https://stickyfloors.net/gig/${gigDetails.slug}`}
        />
      </Helmet>
      <FutureGigView
        venueImg={`https://res.cloudinary.com/stickyfloors/image/upload/q_80/v1522329535/${gigDetails.venueData.images.sq}.jpg`}
        title={gigDetails.title}
        date={gigDetails.date}
        img={gigDetails.img || {}}
        venueName={gigDetails.venueData.name}
        venueSlug={gigDetails.venueData.slug}
        venueAddres={gigDetails.venueData.addres}
        venuePostcode={gigDetails.venueData.postcode}
        loc={gigDetails.venueData.loc}
        venueCity={gigDetails.venueData.city}
        price={gigDetails.price}
        ticketsLink={gigDetails.ticketsLink}
        description={gigDetails.description}
        bands={gigDetails.bands}
        attribution={gigDetails.attribution}
        slug={gigDetails.slug}
        similarGigs={similarGigs}
        isSimilarGigsLoading={isSimilarGigsLoading}
        isFirstPage={isFirstPage}
      />
    </>
  );
};

// Static method for SSR data fetching
GigDetailsView.getInitialProps = (params) => {
  return [getGig(params), getSimilarGigs(params)];
};

export default GigDetailsView;
