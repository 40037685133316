import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { getVenue, resetVenueData } from './action-creators';
import GigsList from '../../components/gigs-list/gigs-list';
import BackButton from '../../components/back-button/back-button';
import HeroImage from '../../components/UIComponents/hero-image/hero-image';
import LocationButton from '../../components/UIComponents/location-button/location-button';
import styles from './venue-details-view-styles.scss';
import viewStyles from '../views-styles.scss';

const VenueDetailsView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const venueDetails = useSelector(
    (state) => state.venueDetailsData.venueDetails
  );
  const isLoading = useSelector((state) => state.venueDetailsData.isLoading);
  const isFirstPage = useSelector((state) => state.router.routes.length === 0);

  useEffect(() => {
    if (venueDetails.slug !== params.slug) {
      dispatch(resetVenueData());
      dispatch(getVenue(params));
    }
  }, [dispatch, params, venueDetails.slug]);

  if (isLoading || isLoading === undefined) {
    return <h1>LOADING...</h1>;
  }

  return (
    <div>
      <Helmet title={`Gigs at ${venueDetails.name}`}>
        <link
          rel="canonical"
          href={`https://stickyfloors.net/venue/${venueDetails.slug}`}
        />
      </Helmet>
      <BackButton className={viewStyles.backButton} />

      <div>
        <HeroImage
          src={`https://res.cloudinary.com/stickyfloors/image/upload/q_80/v1522329535/${venueDetails.images.sq}.jpg`}
          alt={venueDetails.name}
        />
        <h1 className={viewStyles.h1Name}>{venueDetails.name}</h1>
        <LocationButton
          className={styles.location}
          loc={venueDetails.loc}
          address={`${venueDetails.addres} ${venueDetails.postcode} ${venueDetails.city}`}
          name={venueDetails.name}
        />
      </div>

      <GigsList gigsList={venueDetails.venueGigs || []} />
    </div>
  );
};

// Static method for SSR data fetching
VenueDetailsView.getInitialProps = (params) => {
  return [getVenue(params)];
};

VenueDetailsView.propTypes = {
  venueDetails: PropTypes.object,
  isLoading: PropTypes.bool,
  isFirstPage: PropTypes.bool,
};

export default VenueDetailsView;
