import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import App from '../shared_webapp/app';

import configureStore from '../shared_webapp/redux/configure-store';

const store = configureStore(window.__INITIAL_STATE__);

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

if (process.env.NODE_ENV !== 'production') {
  window.React = React; // enable debugger

  if (
    !container ||
    !container.firstChild ||
    !container.firstChild.attributes ||
    !container.firstChild.attributes['data-react-checksum']
  ) {
    console.error(
      'Server-side React render was discarded. Make sure that your initial render does not contain any client-side code.'
    );
  }
}
