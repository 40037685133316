import React from 'react'

import TwitterButton from './twitter-button'
import FacebookButton from './facebook-button'
import styles from './social-media-bar-styles.scss'
import SoundcloudButton from './soundcloud-button'

const SocialMediaBar = ({
  twitterUrl,
  facebookUrl,
  soundcloudUrl,
  className,
  inverted = false,
}) => (
  <nav className={`${styles.nav} ${className || ''}`}>
    {twitterUrl ? (
      <TwitterButton
        inverted={inverted}
        className={styles.btn}
        url={twitterUrl}
      />
    ) : null}
    {facebookUrl ? (
      <FacebookButton
        inverted={inverted}
        className={styles.btn}
        url={facebookUrl}
      />
    ) : null}
    {soundcloudUrl ? (
      <SoundcloudButton inverted={inverted} url={soundcloudUrl} />
    ) : null}
  </nav>
)

export default SocialMediaBar
