import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import moment from 'moment';

import styles from './gigs-list-styles.scss';

const GigsList = ({ gigsList, markPastGigs = false, showPlace = false }) => (
  <ul>
    {gigsList.map((gig) => (
      <li
        className={`${styles.item} ${
          markPastGigs && moment(gig.date).isBefore(moment())
            ? styles.inThePast
            : ''
        }`}
        key={gig._id}
      >
        <Link
          className={styles.link}
          href={`/gig/${gig.slug}`}
          to={`/gig/${gig.slug}`}
        >
          <time
            dateTime={gig.date}
            className={`${styles.time} ${
              markPastGigs && moment(gig.date).isBefore(moment())
                ? styles.inThePastTime
                : styles.inTheFutureTime
            }`}
          >
            <h3 className={styles.day}>{moment(gig.date).format('ddd')} </h3>
            <h3 className={styles.monthDay}>
              {moment(gig.date).format('Do')}{' '}
            </h3>
            <h3 className={styles.month}>
              {moment(gig.date).format('MMM YY')}
            </h3>
          </time>
          <div className={styles.infoContainer}>
            <h3>{gig.title}</h3>
            {showPlace ? <h4>{gig.venueData.name}</h4> : null}
          </div>
        </Link>
      </li>
    ))}
  </ul>
);

GigsList.propTypes = {
  gigsList: PropTypes.array.isRequired,
  markPastGigs: PropTypes.bool,
  showPlace: PropTypes.bool,
};

export default GigsList;
