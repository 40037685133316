import React from 'react'
import SocialMediaLink from './social-media-link/social-media-link'
import FacebookIcon from '../icons/facebook-icon'

const FacebookButton = ({ url, className, inverted }) => (
  <SocialMediaLink inverted={inverted} className={className} href={url}>
    <FacebookIcon />
  </SocialMediaLink>
)
export default FacebookButton
