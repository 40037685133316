import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import HeroImage from '../UIComponents/hero-image/hero-image';
import LocationButton from '../UIComponents/location-button/location-button';
import styles from './venue-list-item.styles.scss';

function VenueListItem(props) {
  return (
    <li className={styles.listItem}>
      <Link
        href={`/venue/${props.venue.slug}`}
        to={`/venue/${props.venue.slug}`}
      >
        <h2 className={styles.nameHeading}>{props.venue.name}</h2>
        <HeroImage
          className={styles.wrapperImg}
          src={`https://res.cloudinary.com/stickyfloors/image/upload/q_80/v1522329535/${
            props.venue.images.sq
          }.jpg`}
          alt={props.venue.name}
        />
      </Link>
      <LocationButton
        className={styles.location}
        loc={props.venue.loc}
        address={`${props.venue.addres} ${props.venue.postcode} ${
          props.venue.city
        }`}
        name={props.venue.name}
      />
    </li>
  );
}

VenueListItem.propTypes = {
  venue: PropTypes.shape({
    name: PropTypes.string.isRequired,
    images: PropTypes.shape({
      banner: PropTypes.string.isRequired,
      sq: PropTypes.string.isRequired,
    }),
    city: PropTypes.string.isRequired,
    addres: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default VenueListItem;
