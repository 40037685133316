import React from 'react'
import PropTypes from 'prop-types'

function StickyFloorsText({ className = '', textData }) {
  const renderLetter = (letter, style, index) => (
    <span key={index} style={style}>
      {letter}
    </span>
  );

  return (
    <span className={className}>
      {textData.map((letterData, index) =>
        renderLetter(letterData.letter, letterData.style, index)
      )}
    </span>
  );
}

StickyFloorsText.propTypes = {
  className: PropTypes.string,
  textData: PropTypes.array,
  fontSize: PropTypes.array,
}

export default StickyFloorsText
