import fetch from 'isomorphic-fetch';

const baseURL =
  typeof window === 'undefined'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8000}`
    : '';
export const REQUEST_ARCHIVE = 'REQUEST_ARCHIVE';
export const RECIEVE_ARCHIVE = 'RECIEVE_ARCHIVE';

export function requestArchive() {
  return { type: REQUEST_ARCHIVE };
}

export function receiveArchive(archiveData) {
  return { type: RECIEVE_ARCHIVE, archiveData };
}

export function getArchive({ page }, limit) {
  return (dispatch) => {
    dispatch(requestArchive());
    return fetch(`${baseURL}/api/gigs/archive`, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        page: page || 1,
        limit,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveArchive(res));
      });
  };
}
