import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './share-button-styles.scss';
import ShareIconIOS from '../../icons/share-icon-ios';
import ShareIconAndroid from '../../icons/share-icon-android';

const ShareButton = ({ title, text = '', url, className = '' }) => {
  const [isSharingAvailable, setIsSharingAvailable] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    setIsSharingAvailable(!!navigator.share);
    setIsAndroid(navigator.userAgent.indexOf('Android') >= 0);
  }, []);

  const handleClick = () => {
    navigator.share({
      title,
      text,
      url,
    });
  };

  const showClassName = isSharingAvailable ? '' : ` ${styles.dontShow}`;
  const buttonClassName = `${styles.shareButton}${showClassName} ${className}`;

  return (
    <button
      className={buttonClassName}
      aria-label="Share button"
      onClick={handleClick}
    >
      {isAndroid ? (
        <ShareIconAndroid className={styles.icon} />
      ) : (
        <ShareIconIOS className={styles.icon} />
      )}
    </button>
  );
};

ShareButton.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ShareButton;
