import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { getBand, getBandGigs } from './action-creators';
import BackButton from '../../components/back-button/back-button';
import GigsList from '../../components/gigs-list/gigs-list';
import HeroImage from '../../components/UIComponents/hero-image/hero-image';
import Accordion from '../../components/accordion/accordion';
import HtmlText from '../../components/html-text/html-text';
import styles from './band-details-view-styles.scss';
import viewStyles from '../views-styles.scss';
import { NO_IMAGE_ID } from '../../../consts/image';
const imageSrc = `https://res.cloudinary.com/stickyfloors/image/upload/c_lfill,g_face:auto,h_600,q_60,w_600/v1522329535/${NO_IMAGE_ID}.jpg`;

const BandDetailsView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const bandDetails = useSelector((state) => state.bandDetailsData.bandDetails);
  const bandGigsData = useSelector((state) => state.bandDetailsData.bandGigs);
  const isFirstPage = useSelector((state) => state.router.routes.length === 0);

  useEffect(() => {
    dispatch(getBand(params));
    dispatch(getBandGigs(params));
  }, [dispatch, params]);

  const description =
    bandDetails.description && bandDetails.description.text
      ? bandDetails.description.text.replace('. ', '.</br>')
      : '';

  return (
    <div>
      <BackButton className={viewStyles.backButton} />
      <Helmet title={`${bandDetails.name}, gigs and info`}>
        <link
          rel="canonical"
          href={`https://stickyfloors.net/band/${bandDetails.slug}`}
        />
      </Helmet>
      <HeroImage className={styles.image} src={imageSrc} alt="music stage" />
      <h1 className={viewStyles.h1Name}>{bandDetails.name}</h1>
      {description && description.length > 5 && (
        <section className={styles.about}>
          <Accordion
            open={false}
            collapsed={false}
            header={<h3 className={viewStyles.accHeader}>About the band</h3>}
          >
            <HtmlText
              htmlText={description}
              source={bandDetails.description && bandDetails.description.source}
              sourceUrl={
                bandDetails.description && bandDetails.description.sourceUrl
              }
            />
          </Accordion>
        </section>
      )}

      <h2 className={styles.gigs}>Gigs</h2>
      <GigsList showPlace markPastGigs gigsList={bandGigsData || []} />
    </div>
  );
};

// Static method for SSR data fetching
BandDetailsView.getInitialProps = (params) => {
  return [getBand(params), getBandGigs(params)];
};

BandDetailsView.propTypes = {
  bandDetails: PropTypes.object,
  bandGigsData: PropTypes.array,
  isFirstPage: PropTypes.bool,
};

export default BandDetailsView;
