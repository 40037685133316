import React from 'react';
import { PropTypes } from 'prop-types';

const SoundcloudIcon = ({ className = ' ' }) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 242.667 242.667"
    xmlSpace="preserve"
    className={className}
  >
    <path
      d="M0,0v242.667h242.667V0H0z M37.474,153.126c-1.869,0-3.384-5.943-3.384-13.274s1.515-13.274,3.384-13.274
	s3.384,5.943,3.384,13.274S39.343,153.126,37.474,153.126z M54.392,160.934c-1.869,0-3.384-11.187-3.384-24.986
	s1.515-24.986,3.384-24.986s3.384,11.187,3.384,24.986S56.26,160.934,54.392,160.934z M70.725,160.934
	c-1.869,0-3.384-14.042-3.384-31.363c0-17.321,1.515-31.363,3.384-31.363s3.384,14.042,3.384,31.363
	C74.108,146.893,72.594,160.934,70.725,160.934z M87.903,160.934c-1.869,0-3.383-13.65-3.383-30.488s1.515-30.488,3.383-30.488
	c1.869,0,3.384,13.65,3.384,30.488S89.771,160.934,87.903,160.934z M104.3,160.934c-1.869,0-3.384-15.382-3.384-34.356
	c0-18.974,1.515-34.356,3.384-34.356s3.384,15.382,3.384,34.356C107.683,145.553,106.168,160.934,104.3,160.934z M199.039,154.948
	c-7.288,3.904-15.876,4.685-15.876,4.685h-66.369V84.415c0,0,26.808-9.11,42.945,6.246c16.137,15.356,13.534,28.109,13.534,28.109
	s19.781-7.548,30.192,5.206C213.874,136.729,206.326,151.044,199.039,154.948z"
    />
  </svg>
);

SoundcloudIcon.propTypes = {
  className: PropTypes.string,
};

export default SoundcloudIcon;
