import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import HeroImage from '../../../components/UIComponents/hero-image/hero-image';
import BackButton from '../../../components/back-button/back-button';
import LocationButton from '../../../components/UIComponents/location-button/location-button';
import PrimaryLinkButton from '../../../components/UIComponents/primary-button/primary-link-button';
import Accordion from '../../../components/accordion/accordion';
import HtmlText from '../../../components/html-text/html-text';
import GigListCompact from '../../../components/gig-list-compact/gig-list-compact';
import styles from './future-gigs-view-styles.scss';
import viewStyles from '../../views-styles.scss';
import { getPriceString } from '../../../utils/parsing_utils';
import ShareButton from '../../../components/UIComponents/share-button/share-button';
import TooLateNotice from '../../../components/too-late-notice/too-late-notice';
const TicketLink = ({
  price,
  ticketsLink,
  attribution,
  className,
  cb = () => null,
}) => {
  let link = null;
  const priceString = getPriceString(price);
  if (ticketsLink && ticketsLink.indexOf('http') > -1) {
    link = (
      <PrimaryLinkButton
        className={className}
        href={ticketsLink}
        target="_blank"
        cb={cb}
      >
        Tickets {priceString}
      </PrimaryLinkButton>
    );
  } else if (attribution && attribution[0]) {
    link = (
      <PrimaryLinkButton
        className={className}
        href={attribution[0].url}
        target="_blank"
        cb={cb}
      >
        {priceString === '' ? 'MORE DETAILS' : `PRICE: ${priceString}`}
      </PrimaryLinkButton>
    );
  }
  return link;
};

const FutureGigView = ({
  venueImg,
  title,
  date,
  img,
  venueName,
  venueSlug,
  venueAddres,
  venuePostcode,
  loc,
  venueCity,
  price,
  ticketsLink,
  description,
  bands,
  attribution,
  slug,
  similarGigs,
  isSimilarGigsLoading,
  isFirstPage,
}) => {
  const getimg = (id) => {
    return id
      ? `https://res.cloudinary.com/stickyfloors/image/upload/c_fill,e_grayscale,g_faces,h_600,q_auto:best,w_600/v1541513319/${id}.jpg`
      : null;
  };

  const isFuture = moment(date).isAfter(moment());
  const formattedDate = moment().isSame(date, 'year')
    ? `${moment(date).format('dddd Do MMMM')}`
    : `${moment(date).format('dddd Do MMMM YYYY')}`;

  return (
    <div>
      {!isFuture && <TooLateNotice date={date} />}
      <BackButton
        className={viewStyles.backButton}
        cb={FutureGigView.onBackClick}
      />
      <HeroImage
        alt={img?.alt || venueName}
        src={getimg(img.cloudinaryId) || venueImg}
      />
      <div className={styles.contentContainer}>
        <h3 className={styles.date}>
          <time dateTime={date}>{formattedDate}</time>
        </h3>
        <div className={styles.titleSection}>
          <div>
            <h1 className={styles.title}>{title}</h1>

            <h3 className={styles.doors}>
              Doors: <time dateTime={date}>{moment(date).format('H:mm')}</time>
            </h3>
          </div>{' '}
          <ShareButton
            className={styles.shareButton}
            url={`https://stickyfloors.net/gig/${slug}`}
            title={title}
          />
        </div>
        <LocationButton
          loc={loc}
          className={styles.locationLink}
          address={`${venueAddres} ${venuePostcode} ${venueCity}`}
          name={venueName}
          cb={FutureGigView.onLocationClick}
        />

        <nav className={styles.secondaryNav}>
          {!isFuture && (
            <PrimaryLinkButton
              external={false}
              className={styles.venueLink}
              onClick={FutureGigView.onMoreatVenueClick}
              to={`/venue/${venueSlug}`}
              href={`/venue/${venueSlug}`}
            >
              More Gigs at <span className={styles.venueName}>{venueName}</span>
            </PrimaryLinkButton>
          )}
          {isFuture && (
            <TicketLink
              className={styles.ticketButton}
              price={price}
              ticketsLink={ticketsLink}
              attribution={attribution}
              cb={FutureGigView.ticketLinkClick}
            />
          )}
        </nav>

        {description ? (
          <section className={styles.section}>
            <Accordion
              open
              header={<h3 className={viewStyles.accHeader}>Gig Description</h3>}
            >
              <HtmlText
                className={styles.description}
                htmlText={description}
                attribution={attribution}
              />
            </Accordion>
          </section>
        ) : null}
        {similarGigs.length > 0 ? (
          <section className={styles.section}>
            <Accordion
              open
              header={<h3 className={viewStyles.accHeader}>Similar Gigs</h3>}
            >
              <GigListCompact
                cb={FutureGigView.onSimilarClick}
                className={styles.similar}
                gigs={similarGigs}
              />
            </Accordion>
          </section>
        ) : null}
      </div>
    </div>
  );
};

FutureGigView.propTypes = {
  venueImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  img: PropTypes.object,
  venueName: PropTypes.string.isRequired,
  venueSlug: PropTypes.string.isRequired,
  venueAddres: PropTypes.string.isRequired,
  venuePostcode: PropTypes.string.isRequired,
  loc: PropTypes.array.isRequired,
  venueCity: PropTypes.string.isRequired,
  price: PropTypes.string,
  ticketsLink: PropTypes.string,
  description: PropTypes.string,
  bands: PropTypes.array,
  attribution: PropTypes.array,
  slug: PropTypes.string.isRequired,
  similarGigs: PropTypes.array.isRequired,
  isSimilarGigsLoading: PropTypes.bool,
  isFirstPage: PropTypes.bool,
};

export default FutureGigView;
