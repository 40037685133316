import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import viewStyles from '../views-styles.scss';
import BackButton from '../../components/back-button/back-button';
import SocialMediaBar from '../../components/social-media-bar/social-media-bar';
import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text';
import styles from './about-view-styles.scss';

const AboutView = () => {
  const isFirstPage = useSelector(state => state.router.routes.length === 0);
  const aboutHeader = useSelector(state => state.randomText.about);

  return (
    <section className={`${viewStyles.section} ${styles.about}`}>
      <Helmet title="About">
        <link rel="canonical" href="https://stickyfloors.net/about" />
      </Helmet>
      <BackButton
        className={viewStyles.backButton}
        cb={() => {/* No analytics needed */}}
      />
      <h1>
        <StickyFloorsText
          className={viewStyles.randomLine}
          textData={aboutHeader[0]}
        />
      </h1>
      <section>
        <h2>About</h2>
        <p>
          StickyFloors has been listing small, independent gigs in London
          since 2016. We do our best to keep the details accurate, but
          sometimes things change or slip through the cracks—so always check
          the venue's website to be sure.
        </p>
      </section>

      <section>
        <h2>Archive</h2>
        <p>
          StickyFloors features an archive of over 14,000 gigs and counting,
          capturing years of London's live music shenanigans. Whether you're
          reminiscing about past shows or just having a nose around, it's all{' '}
          <Link
            className={styles.archiveLink}
            to="/gigs/archive/1"
          >
            here
          </Link>{' '}
          to explore.
        </p>
      </section>

      <section>
        <h2>Contact</h2>
        <p className={styles.pContact}>
          If you're a venue or promoter and want your gigs listed, get in
          touch{' '}
          <a
            className={styles.link}
            href="mailto:stickyfloorsgigs@gmail.com?Subject=hello%20there%21"
          >
            stickyfloorsgigs@gmail.com
          </a>
        </p>
        <SocialMediaBar
          inverted
          className={viewStyles.socialMediaBar}
          twitterUrl="https://twitter.com/_stickyfloors_"
          facebookUrl="https://www.facebook.com/StickyFloors-1170717093093696"
          soundcloudUrl="https://soundcloud.com/stickyfloors"
        />
      </section>
    </section>
  );
};

AboutView.propTypes = {
  isFirstPage: PropTypes.bool,
  aboutHeader: PropTypes.array,
};

// Static method for SSR data fetching
AboutView.getInitialProps = () => {
  return [];
};

export default AboutView;
