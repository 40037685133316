import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { toggleMenu } from '../../redux/ui-reducer/action-creators';

import MenuIcon from '../icons/menu-icon';
import CloseIcon from '../icons/CloseIcon';

export const ManuButton = ({ onClick = () => null, isMenuOpen = false }) => (
  <button
    aria-label={isMenuOpen ? 'Close side navigation' : 'Open side navgiation'}
    onClick={() => onClick(isMenuOpen)}
  >
    {isMenuOpen ? (
      <div style={{ width: '40px' }}>
        <CloseIcon />
      </div>
    ) : (
      <MenuIcon />
    )}
  </button>
);

const mapDispatchToProps = (dispatch) => ({
  onClick: (isMenuOpen) => {
    // No analytics needed
    dispatch(toggleMenu());
  },
});

const mapStateToProps = ({ uiState }) => ({
  isMenuOpen: uiState.isMenuOpen,
});

ManuButton.propTypes = {
  onClick: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManuButton);
