import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getPriceString } from '../../utils/parsing_utils';
import { replaceTracks } from '../../redux/tracksToPlayReducer/actionCreators';

import styles from './gigs-list-with-date-styles.scss';

export const ListHeader = ({ date }) => {
  const momentDate = moment(date);
  const now = moment();
  const when = moment
    .duration(momentDate.startOf('day').diff(now.startOf('day')))
    .asDays();
  let str;
  if (when === 0) {
    str = 'tonight';
  } else if (when === 1) {
    str = 'tomorrow';
  }
  return (
    <h2 className={styles.stickyDate}>
      <time dateTime={date}>{momentDate.format('ddd Do MMMM')}</time>
      {str && <span className={styles.tonight}>{str}</span>}
    </h2>
  );
};

ListHeader.propTypes = {
  date: PropTypes.string.isRequired,
};

export const ListItem = ({
  onPlayClick = () => null,
  tracks,
  slug,
  title,
  venueName,
  genre = [],
  price = '',
}) => (
  <div className={styles.listItem}>

    <Link className={styles.link} to={`/gig/${slug}`} href={`/gig/${slug}`}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {genre.length > 0 && (
        <h5 className={styles.genre}>{genre.join(' // ')}</h5>
      )}
      <h4>{venueName}</h4>
      <h5>{`${getPriceString(price)}`}</h5>
    </Link>
  </div>
);

ListItem.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tracks: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  venueName: PropTypes.string.isRequired,
  genre: PropTypes.arrayOf(PropTypes.string),
  onPlayClick: PropTypes.func,
};

const GigsListWithDate = ({ onPlayClick, gigsList }) => (
  <div>
    <ListHeader date={gigsList.date} />
    {gigsList.gigs.map((gig) => (
      <ListItem
        key={gig._id}
        slug={gig.slug}
        venueName={gig.venueData.name}
        genre={gig.genre}
        tracks={gig.tracks}
        title={gig.title}
        onPlayClick={onPlayClick}
        price={gig.price}
      />
    ))}
  </div>
);

GigsListWithDate.propTypes = {
  gigsList: PropTypes.object.isRequired,
  onPlayClick: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onPlayClick: (tracks) => {
    // No analytics needed
    dispatch(replaceTracks(tracks));
  },
});

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GigsListWithDate);
