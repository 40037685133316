import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { getArchive } from './action-creators';
import GigsList from '../../components/gigs-list/gigs-list';
import BackButton from '../../components/back-button/back-button';
import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text';
import Pagination from '../../components/pagination/pagination';
import viewStyles from '../views-styles.scss';

const PAGE_SIZE = 50;

const ArchiveView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const archiveGigs = useSelector((state) => state.archiveData.gigs);
  const pagination = useSelector((state) => state.archiveData.pagination);
  const isLoading = useSelector((state) => state.archiveData.isLoading);
  const archiveTextData = useSelector((state) => state.randomText.archive);

  useEffect(() => {
    dispatch(getArchive(params, PAGE_SIZE));
  }, [dispatch, params]);

  const onPageChange = (pageNo) => {
    navigate(`/gigs/archive/${pageNo}`);
  };

  return (
    <div>
      <Helmet title="Gigs archive" />
      <BackButton className={viewStyles.backButton} />
      <h1>
        <StickyFloorsText
          className={viewStyles.randomLine}
          textData={archiveTextData[0]}
        />
        <StickyFloorsText
          className={viewStyles.randomLine}
          textData={archiveTextData[1]}
        />
      </h1>
      {isLoading || isLoading === undefined ? (
        <h1>LOADING...</h1>
      ) : (
        <div>
          <GigsList gigsList={archiveGigs || []} />
          <div className={viewStyles.pagination}>
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

// Static method for SSR data fetching
ArchiveView.getInitialProps = (params) => {
  return [getArchive(params, PAGE_SIZE)];
};

ArchiveView.propTypes = {
  archiveGigs: PropTypes.array,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  archiveTextData: PropTypes.array,
};

export default ArchiveView;
