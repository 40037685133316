import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';

import venuesReducer from '../views/venues-view/reducer';
import gigsReducer from '../views/gigs-view/reducer';
import bandsReducer from '../views/bands-view/reducer';
import venueDetailsReducer from '../views/venue-details-view/reducer';
import gigDetailsReducer from '../views/gig-details-view/reducer';
import bandDetailsReducer from '../views/band-details-view/reducer';
import archiveReducer from '../views/archive-view/reducer';
import randomTextReducer from './random-text-reducer/random-text-reducer';
import tracksToPlayReducer from './tracksToPlayReducer/tracksToPlayReducer';
import uiReducer from './ui-reducer/ui-reducer';
import routerReducer from './router-reducer/router-reducer';

const rootReducer = combineReducers({
  venuesData: venuesReducer,
  archiveData: archiveReducer,
  gigsData: gigsReducer,
  bandsData: bandsReducer,
  venueDetailsData: venueDetailsReducer,
  gigDetailsData: gigDetailsReducer,
  bandDetailsData: bandDetailsReducer,
  randomText: randomTextReducer,
  tracksToPlay: tracksToPlayReducer,
  uiState: uiReducer,
  router: routerReducer,
});

const configureStore = (initialState = {}) => {
  const middleware = [thunk];

  // Add Redux DevTools Extension support in development
  let composeEnhancers = compose;

  if (process.env.CLIENT && process.env.NODE_ENV !== 'production') {
    composeEnhancers =
      (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose;
  }

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  /* // Enable Webpack hot module replacement for reducers in development
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer);
    });
  } */

  return store;
};

export default configureStore;
