import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './accordion-styles.scss';

const Accordion = ({
  header,
  children,
  open = false,
  collapsed = true,
  className = '',
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = () => {
    if (collapsed) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={`${styles.accordion} ${className}`}>
      <div
        className={`${styles.header} ${collapsed ? styles.clickable : ''}`}
        onClick={toggleOpen}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleOpen();
          }
        }}
      >
        {header}
      </div>
      <div className={`${styles.content} ${isOpen ? styles.open : ''}`}>
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  collapsed: PropTypes.bool,
  className: PropTypes.string,
};

export default Accordion;
